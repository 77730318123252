import React from "react"

import Layout from "../components/layouts/main"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Page Does not Exist</p>
    <p><a href="/projects/japanese/">Return to Main Page</a></p>
  </Layout>
)

export default NotFoundPage
